* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');

:root {
  --primary-color: rgba(255, 221, 0, 1);
  --secondary-color: rgb(255, 255, 255);
  --background-color: #ffffff;
  --text-color: rgb(0, 0, 0);
}

.accordionSection3 {
  background-color: var(--background-color);
  padding: 50px;
  font-family: "Noto Sans Armenian", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.header3 {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  gap: 4px;
}

.header3 p {
  color: var(--text-color);
  font-size: 20px;
  font-weight: bold;
}

.header3 h2 {
  color: var(--text-color);
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
}

.sectionner3 {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.section3 {
  padding: 20px;
  transition: all 0.3s ease;
  border-bottom: 1px solid var(--text-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section3.active3 {
  background: var(--primary-color);
  color: var(--text-color);
}

.sectionHeader3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.sectionHeader3 h3 {
  font-size: 24px;
  color: var(--text-color);
  flex: 1;
  text-align: left;
}

.ii3 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.section3.active3 .sectionHeader3 h3 {
  color: var(--text-color);
}

.index3 {
  font-size: 24px;
  color: black;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
}

.section3.active3 .index3 {
  color: var(--text-color);
}

.arrow3 {
  font-size: 36px;
  color: black;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section3.active3 .arrow3 {
  color: var(--text-color);
  width: 40px;
  padding: 4px;
  font-size: 30px;
  height: 40px;
  background-color: #973131;
  border-radius: 50%;
}

.sectionContent3 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 500px;
  margin-top: 20px;
}

.sectionContent3 p {
  font-size: 18px;
  line-height: 1.5;
  margin-right: 20px;
  flex: 1;
  text-align: left;
}

.arrow3.open3 {
  align-self: flex-start;
  margin-top: -10px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .accordionSection3 {
      padding: 20px;
      gap: 20px;
      max-width: 393px;
      margin: 0 auto;
  }

  .header3 p {
      font-size: 16px;
  }

  .header3 h2 {
      font-size: 28px;
  }

  .section3 {
      padding: 15px;
      max-width: 393px;
      width: 100%;
      position: relative;
  }

  .section3.active3 {
      max-width: 393px;
      width: 100%;
      max-height: 264px;
      overflow-y: auto;
  }

  .sectionHeader3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
  }

  .section3.active3 .sectionHeader3 {
      flex-direction: column;
      align-items: flex-start;
  }

  .sectionHeader3 h3 {
      font-size: 20px;
      margin-bottom: 10px;
  }

  .ii3 {
      display: flex;
      align-items: center;
      max-width: 100%;
      width: 100%;
  }

  .section3.active3 .ii3 {
      display: none;
  }

  .index3 {
      font-size: 20px;
      width: 40px;
      height: 40px;
      line-height: 40px;
  }

  .arrow3 {
      font-size: 36px;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 15px;
      right: 15px;
  }

  .section3.active3 .arrow3 {
    color: var(--text-color);
    background-color: var(--primary-color);
      border-radius: 50%;
  }

  .sectionContent3 {
      display: none;
      width: 100%;
  }

  .section3.active3 .sectionContent3 {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      max-width: 393px;
  }

  .sectionContent3 p {
      font-size: 16px;
      word-break: break-word;
  }
}
