* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');

:root {
  --primary-color: rgba(255, 221, 0, 1);
  --secondary-color: rgb(255, 255, 255);
  --background-color: #ffffff;
  --text-color: rgb(0, 0, 0);
}

.projectsContainer5 {
  font-family: "Noto Sans Armenian", sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  padding: 50px;
}

.headerSection5 {
  text-align: left;
  margin-bottom: 50px;
}

.subtitle5 {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: bold;
}

.mainTitle5 {
  color: var(--text-color);
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
}

.projectsContent5 {
  display: flex;
  justify-content: space-around;
}

.projectCard5 {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}

.projectCard5 p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .projectsContainer5 {
    padding: 20px;
  }

  .headerSection5 {
    text-align: center;
  }

  .subtitle5 {
    font-size: 18px;
  }

  .mainTitle5 {
    font-size: 25px;
  }

  .projectsContent5 {
    flex-direction: column;
    align-items: center;
  }

  .projectCard5 {
    width: 90%;
    margin-bottom: 20px;
  }

  .projectCard5 p {
    font-size: 16px;
  }
}
