* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: rgba(255, 221, 0, 1);
  --secondary-color: rgb(255, 255, 255);
  --background-color: #ffffff;
  --text-color: rgb(0, 0, 0);
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');

.abilitiesContainer4 {
  background-color: var(--background-color);
  font-family: "Noto Sans Armenian", sans-serif;
  color: var(--text-color);
}

.headerBackground4 {
  background-image: url('../images/abilliti.png'); /* Update this path */
  background-size: cover;
  background-position: center;
  padding: 100px;
}

.headerSection4 {
  text-align: left;
  padding: 20px;
}

.subtitle4 {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

.mainTitle4 {
  color: var(--text-color);
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
}

.contentSection4 {
  display: flex;
  justify-content: space-around;
  max-width: 1000px;
  padding: 10px;
  margin: auto;
}

.leftColumn4,
.rightColumn4 {
  width: 48%;
}

.leftColumn4 p,
.rightColumn4 p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .headerBackground4 {
    padding: 50px 20px;
  }

  .headerSection4 {
    text-align: center;
  }

  .subtitle4 {
    font-size: 18px;
  }

  .mainTitle4 {
    font-size: 24px;
  }

  .contentSection4 {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .leftColumn4,
  .rightColumn4 {
    width: 100%;
  }

  .leftColumn4 p,
  .rightColumn4 p {
    font-size: 16px;
  }
}
