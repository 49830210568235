* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: rgba(255, 221, 0, 1);
  --secondary-color: rgb(255, 255, 255);
  --background-color: #ffffff;
  --text-color: rgb(0, 0, 0);
}

.mainSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* background-image: linear-gradient(var(--primary-color), var(--background-color)); */
  font-family: 'Josefin Sans', cursive;
  font-family: "Noto Sans Armenian", sans-serif;
  gap: 34px;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');

.textPart {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  font-family: "Noto Sans Armenian", sans-serif;
  width: 1170px;
}

.f1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 200px;
}

.a2 h1 {
  color: var(--text-color);
  font-weight: 800;
  font-size: 43px;
}

.a35 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.a35 h1 {
  font-weight: 600;
  font-size: 30px;
  color: var(--text-color);
}

.a35 a {
  width: 50px;
  height: 50px;
  background-color: var(--secondary-color);
  color: black;
  border: none;
  font-size: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.a1 h1 {
  color: var(--text-color);
  font-weight: 800;
  font-size: 43px;
}

.imgPart {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: baseline;
}

.imgPart p {
  width: 633px;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
}

.imgPart img {
  width: 1121px;
  height: 547px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .textPart {
    width: 100%;
    padding: 20px;
  }

  .f1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .a2 h1,
  .a1 h1 {
    font-size: 32px;
    text-align: left;
  }

  .a35 {
    flex-direction: row;
    align-items: center;
  }

  .a35 h1 {
    font-size: 25px;
    text-align: left;
  }

  #imgPart p {
    display: none;
  }

  .a35 a {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-top: 0;
    border-radius: 50%;
  }

  .imgPart {
    width: 100%;
    align-items: center;
  }

  .imgPart p {
    width: 90%;
    font-size: 18px;
    text-align: center;
  }

  .imgPart img {
    width: 90%;
    height: auto;
    border-radius: 90px !important;
  }
}

/* Desktop Specific Styles */
@media (min-width: 601px) {
  .a2 {
    display: flex;
    flex-direction: column;
  }

  .a35 {
    width: 300px;
    display: flex;
    align-items: center;
  }

  .a35 h1 {
    margin-right: 10px;
  }

  .a35 a {
    width: 50px;
    height: 50px;
    color: var(--text-color);
    font-size: 25px;
    border-radius: 50%;
  }
}
