* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');

:root {
  --primary-color: rgba(255, 221, 0, 1);
  --secondary-color: rgb(255, 255, 255);
  --background-color: #ffffff;
  --text-color: rgb(0, 0, 0);
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: var(--background-color);
  height: 103px;
  font-family: "Noto Sans Armenian", sans-serif;
}

.header .logo {
  font-size: 1.5em;
  font-weight: bold;
}

.desktop-nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.desktop-nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.language-switch img {
  width: 32px;
  height: 20px;
  cursor: pointer;
}

.language-switch {
  display: inline-flex;
  align-items: center;
  gap: 20px;
}

#contacts-button {
  text-decoration: none;
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 12px 20px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
}

.mic {
  display: none;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .desktop-nav {
    display: none;
  }

  .language-switch {
    display: none;
  }

  #contacts-button {
    display: none;
  }

  .menu-icon {
    display: block;
    font-size: 40px !important;
    color: #000000;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.952);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .header .logo img {
    width: 300px;
    font-weight: bold;
  }

  .mic {
    display: block;
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 32px;
    cursor: pointer;
  }

  .mobile-menu nav ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .mobile-menu nav ul li a {
    text-decoration: none;
    color: var(--text-color);
    font-size: 24px;
    font-weight: 600;
  }

  .language-switch-mobile {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }

  .language-switch-mobile img {
    width: 32px;
    height: 20px;
    cursor: pointer;
  }
}

.cursorpointerptilni{
  cursor: pointer;
}