.team-container {
    padding: 20px;
    background-color: #f0f4f8;
    text-align: center;
  }
  
  .team-container h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 40px;
  }
  
  .team-row {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-bottom: 40px;
    width: 100%;
  }
  
  .team-member {
    /* background: #fff; */
    padding: 0px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    text-align: center;
    flex: 1;
    max-width: 300px; /* Increased width */
  }
  
  .team-member img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .team-member h2 {
    text-align: left;
    font-size: 1.2rem;
    color: #333;
    margin: 10px 0 5px 0;
  }
  
  .team-member p {
    text-align: left;

    font-size: 1rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .team-row {
      flex-direction: column;
      align-items: center;
    }
  
    .team-member {
      width: 90%;
      margin-bottom: 20px;
      max-width: none;
    }
  }
  