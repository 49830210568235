:root {
  --primary-color: rgba(255, 221, 0, 1);
  --secondary-color: rgb(255, 255, 255);
  --background-color: #ffffff;
  --text-color: rgb(0, 0, 0);
}

.container7 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 20px;
    /* background-image: linear-gradient(#973131,  #DFEEF0); */
  }
  
  .text-content7 {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 50px;
    margin-right: 20px;
    max-width: 600px;
  }
  
   #imgC {
    max-width: 50%;
    height: auto;
    border-radius: 8px;
  }
  
  .container7 h1 {
    font-weight: 700;
    font-size: 50px;
    color: white;
  }
  
  .container7 p {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;}
  
  @media (max-width: 768px) {
    .container7 {
      flex-direction: column;
      text-align: center;
    }
  
    .text-content7 {
        text-align: left;
      margin: 0 0 20px 0;
    }
  
    #imgC{
        /* display: none; */
        visibility: hidden;
    }

    .image-content7 img {
        display: none;
      max-width: 100%;
    }
  }
  