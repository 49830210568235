* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');

:root {
  --primary-color: rgba(255, 221, 0, 1);
  --secondary-color: rgb(255, 255, 255);
  --background-color: #ffffff;
  --text-color: rgb(0, 0, 0);
}

.contactFormContainer6 {
  font-family: "Noto Sans Armenian", sans-serif;
  color: var(--text-color);
  padding: 50px;
  background-color: var(--background-color);
}

.formHeader6 {
  text-align: left;
  margin-bottom: 40px;
}

.subtitle6 {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: bold;
}

.mainTitle6 {
  color: var(--text-color);
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
}

.formContent6 {
  display: flex;
  justify-content: space-between;
}

.formFields6 {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.row6 {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.inputField6 {
  padding: 10px;
  height: 70px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
}

textarea.inputField6 {
  height: 150px !important;
  resize: none;
}

.submitButton6 {
  width: 200px;
  height: 50px;
  padding: 10px 20px;
  background-color: var(--secondary-color);
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 10px;
}

.contactInfo6 {
  width: 35%;
}

.contactInfo6 p {
  margin-bottom: 10px;
  font-size: 16px;
}

.contactInfo6 a {
  color: var(--secondary-color);
  text-decoration: none;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .formContent6 {
    flex-direction: column;
    align-items: center;
  }

  .formFields6 {
    width: 100%;
    margin-bottom: 20px;
  }

  .contactInfo6 {
    width: 100%;
    text-align: center;
  }

  .row6 {
    flex-direction: column;
  }

  .inputField6,
  .submitButton6 {
    width: 100%;
  }

  .mainTitle6 {
    color: var(--text-color);
    font-size: 30px;
    line-height: 1.2;
    margin: 0;
  }
}
