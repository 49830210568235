.back-to-top-button {
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
    background-color: rgba(255, 221, 0, 1)!important; 
    color: black !important;
    border-radius: 50% !important;
    z-index: 1000 !important;
    transition: opacity 0.3s ease-in-out !important;
    opacity: 0;
    visibility: hidden;
}

.back-to-top-button.show {
    opacity: 1 !important;
    visibility: visible !important;
}

.back-to-top-button.hide {
    opacity: 0 !important;
    visibility: hidden !important;
}


