* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');
  
  :root {
    --primary-color: rgba(255, 221, 0, 1);
    --secondary-color: rgb(255, 255, 255);
    --background-color: #ffffff;
    --text-color: rgb(0, 0, 0);
  }
  .aboutSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
    padding: 50px;
    font-family: "Noto Sans Armenian", sans-serif;
  }
  
  .textPart2 {
    text-align: left;
    width: 100%;
    margin-bottom: 40px;
  }
  
  .textPart2 .heading {
    color: var(--secondary-color);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .textPart2 h1 {
    font-size: 48px;
    line-height: 1.2;
    color: var(--text-color);
    margin: 0;
  }
  
  .mainPart2 {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
  
  .abImg {
    flex: 1;
    padding-right: 20px;
  }
  
  .abImg img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .abDescription {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .descriptionText {
    font-size: 18px;
    line-height: 1.5;
    color: var(--text-color);
    margin-bottom: 20px;
    margin-top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  .offerHeading {
    font-size: 24px;
    color: var(--text-color);
    margin-bottom: 20px;
  }
  
  .frames {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .aboutButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 30px;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    align-self: flex-start;
  }
  
  /* Mobile Styles */
  @media (max-width: 600px) {
    .aboutSection {
      padding: 20px;
    }
  
    .textPart2 {
      margin-bottom: 20px;
    }
  
    .textPart2 .heading {
      font-size: 20px;
    }
  
    .textPart2 h1 {
      font-size: 36px;
    }
  
    .mainPart2 {
      flex-direction: column;
      align-items: center;
    }
  
    .abImg {
      padding-right: 0;
      margin-bottom: 20px;
    }
  
    .abDescription {
      padding-left: 0;
      align-items: center;
    }
  
    .descriptionText {
      font-size: 16px;
      margin-bottom: 20px;
    }
  
    .offerHeading {
      font-size: 20px;
    }
  
    .frames {
      gap: 10px;
      align-items: flex-start;
      width: 100%;
    }
  
    .frame img {
      width: 100%;
      height: auto;
    }
  
    .aboutButton {
      padding: 10px 20px;
      font-size: 16px;
      align-self: center;
    }
  }
  