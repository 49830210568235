* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');

:root {
  --primary-color: rgba(255, 221, 0, 1);
  --secondary-color: rgb(255, 255, 255);
  --background-color: #ffffff;
  --text-color: rgb(0, 0, 0);
}

.footerContainer6 {
  background-color: var(--primary-color);
  padding: 50px;
  color: white;
  font-family: "Noto Sans Armenian", sans-serif;
}

.footerContainer6 * {
  color: white;
}

.footerContent6 {
  display: flex;
  justify-content: space-between;
}

.footerColumn6 {
  width: 23%;
  text-align: left;
}

.footerColumn6 h2 {
  font-family: 'Times New Roman', serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 20px;
  margin-bottom: 10px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.footerColumn6 h2 .highlight6 {
  color: var(--secondary-color);
}

.footerColumn6 h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.footerColumn6 p {
  font-size: 16px;
  margin-bottom: 10px;
}

.footerColumn6 a {
  color: var(--secondary-color);
  text-decoration: none;
}

.subscription6 {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.subscription6 svg path {
  color: var(--secondary-color);
}

.subscribeText6 {
  font-size: 16px;
  margin-right: 10px;
}

.subscribeArrow6 {
  color: var(--secondary-color);
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .footerContent6 {
      flex-direction: column;
      align-items: center;
  }

  .footerColumn6 h2 {
      display: flex;
      gap: 5px;
  }

  .footerColumn6 {
      width: 100%;
      margin-bottom: 20px;
  }

  .subscription6 {
      justify-content: center;
  }
}
